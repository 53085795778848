.form {
  display: flex;
  flex-direction: column;
}

h2 {
  background-color: #5b9bd5;
  color: white;
  padding: 5px;
  text-align: center;
  margin: 0;
}

.inputBox {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.336);
  height: 40px;
}

.inputLabel {
  display: flex;
  flex-direction: column;
  min-width: 250px;
  max-width: 350px;
  background-color: #deeaf6;
  height: 100%;
  padding: 0;
  justify-content: center;
  border-right:  1px solid rgba(0, 0, 0, 0.336);
}

.input {
  flex: 1;
  border: 0px
}

.radioGroup {
  align-items: center;  
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.radioSeparator {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.336);
  width: 1px;
}

.advice {
  font-size: 14px;
  background-color: #fff2cc;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.336);
}

blockquote {
  font-size: 12px;
  text-align: left;
  padding: 0;
  margin: 10px 0px 15px 5px;
}

.comunicationType {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.336);
}

.comunicationTypeRadio {
  min-width: 250px;
}

.comunicationTypeDesc {
  background-color: rgba(0, 0, 0, 0.090);
  margin: 0;
  padding: 5px;
  flex: 1;
}

.radioFullRow {
  border-bottom: 1px solid rgba(0, 0, 0, 0.336);
  min-height: 30px;
  align-items: center;
}

.radioFullRow > span {
  margin-left: 10px;
}

.textAreaBox {
  border-bottom: 1px solid rgba(0, 0, 0, 0.336);
  height: 100px;
  display: flex;
  flex-direction: column;
}

.textAreaLabel {
  display: block;
  width: 100%;
  background-color: #deeaf6;
  height: 45px;
  padding: 0;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.336);

}

.textAreaInput {
  height: calc(100% - 29px);
  width: 99%;
  border: 0px;
  box-sizing: border-box; 
  resize: none; 
  overflow: auto; 
}

.radioRow {
  display: flex;
  align-items: center;
  padding: 5px;
  gap: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.336);
  flex-wrap: wrap; 
}

.radioRowBox {
  flex: 1; 
}

.radioRowBox input[type="text"] {
  border: 1px solid rgba(0, 0, 0, 0.336);
  margin-left: 3px;
}

.radioRowBox:nth-child(odd) {
  margin-right: auto;
}

.radioRowBox:nth-child(even) {
  margin-left: auto;
}

button {
  background-color: #00bfff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #007acc;
}
